<template>

<div 
  class="creek-chat-toolbar" 
  :class="{ 
    'creek-chat-open': isOpen, 
    'creek-chat-closed': !isOpen
  }"
>

  <div class="creek-chat-inner-open">
    
    <a 
      class="creek-chat-exit" 
      href="javascript:" 
      @click="toggleChatOpen()"
      v-if="!isAlwaysOpen"
    >
      <i class="fa fa-close fa-lg"></i> Exit Chat
    </a>

    <CreekChatPanel />
    <!--
    <button
      @click="$store.commit('openChatPanel')"
    > 
    -->

  </div>

  <a 
    class="creek-chat-inner-closed" 
    @click="toggleChatOpen()" 
    href="javascript:"
  >
  
    <!--<span v-text="userCount"></span> <span v-text="userCount > 1 || userCount == 0 ? 'people' : 'person'"></span> in chat.-->
    
    <i class="fa fa-comment-o fa-lg"></i>

    <span v-text="userCount ? userCount : '1'"></span>
    &nbsp;user<span v-text="userCount > 1 ? 's' : ''"></span> in chat.
  
  </a>

</div>

</template>

<script>

import CreekChatPanel from '@creek-website-toolkit/components/panels/ChatPanel.vue'

export default {
  name: 'CreekChatToolbar',
  props: {
    isAlwaysOpen: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    isOpen(){
      return this.isAlwaysOpen ? true : this.$store.creek.studio.UI.isChatToolbarOpen
    }
  },
  components: {
    CreekChatPanel
  },
  data(){
    return {

    }
  },
}
</script>
