
import {Howl, Howler} from 'howler'

let HowlerInstance = null


export default {
  
  namespaced: true,

  state () {
    return {

      sources: {
        broadcast: null,
        stream: null,
      },

      meta: {
        show: null,
      },

      sourceType: null,

      hasStream: null,
      hasBroadcast: null,

      error: null,

      isLoading: false,
      isPlaying: false,

      seek: null,
      duration: 1,
      position: 0,

      URL: null,

    }
  },
  getters(){
    
  },
  mutations: {

    // Activate Broadcast or Stream
    //
    // There can only be a Broadcast or a Stream -- not both.
    //
    activateBroadcast: (state, payload) => {

      // Meta
      state.sourceType = 'audio'
      // state.sourceType = 'video'
      
      // Sources
      state.sources.stream = null
      state.sources.broadcast = payload
      
      // Boolean shortcuts
      state.hasStream = false
      state.hasBroadcast = true
      
      // URL shortcut.
      state.URL = payload.audio.url
      
      // Default to playing from the beginning.
      state.position = 0



    },
    activateStream: (state, payload) => {

      // Meta
      state.sourceType = 'audio'

      // Video would be here:
      // state.sourceType = 'video'

      // Sources
      state.sources.stream = payload
      state.sources.broadcast = null
      
      // Boolean shortcuts
      state.hasStream = true
      state.hasBroadcast = false

      // URL shortcut.
      state.URL = payload.url
      
      // Streams have no position.
      state.position = null


// if ('mediaSession' in navigator) {
//   // alert(3)
//   navigator.mediaSession.metadata = new MediaMetadata({
//     title: 'ATX Soul Radio',
//     artist: 'ATX Soul Radio.com',
//     album: 'ATX Soul Radio.',
//     artwork: [
//       { src: 'https://dummyimage.com/96x96',   sizes: '96x96',   type: 'image/png' },
//       { src: 'https://dummyimage.com/128x128', sizes: '128x128', type: 'image/png' },
//       { src: 'https://dummyimage.com/192x192', sizes: '192x192', type: 'image/png' },
//       { src: 'https://dummyimage.com/256x256', sizes: '256x256', type: 'image/png' },
//       { src: 'https://dummyimage.com/384x384', sizes: '384x384', type: 'image/png' },
//       { src: 'https://dummyimage.com/512x512', sizes: '512x512', type: 'image/png' },
//     ]
//   });

//   navigator.mediaSession.setActionHandler('play', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('pause', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('stop', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('seekbackward', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('seekforward', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('seekto', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('previoustrack', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('nexttrack', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('skipad', function() { /* Code excerpted. */ });
// }else{
//   // alert(4)
// }



    },

    // Play and Pause
    playBroadcast: (state, payload) => {
      state.isPlaying = true
    },
    pauseBroadcast: (state) => {
      state.isPlaying = false
    },
    playStream: (state, payload) => {
      state.isPlaying = true



// if ('mediaSession' in navigator) {
//   // alert(1)
//   navigator.mediaSession.metadata = new MediaMetadata({
//     title: 'ATX Soul Radio',
//     artist: 'ATX Soul Radio.com',
//     album: 'ATX Soul Radio.',
//     artwork: [
//       { src: 'https://dummyimage.com/96x96',   sizes: '96x96',   type: 'image/png' },
//       { src: 'https://dummyimage.com/128x128', sizes: '128x128', type: 'image/png' },
//       { src: 'https://dummyimage.com/192x192', sizes: '192x192', type: 'image/png' },
//       { src: 'https://dummyimage.com/256x256', sizes: '256x256', type: 'image/png' },
//       { src: 'https://dummyimage.com/384x384', sizes: '384x384', type: 'image/png' },
//       { src: 'https://dummyimage.com/512x512', sizes: '512x512', type: 'image/png' },
//     ]
//   });

//   navigator.mediaSession.setActionHandler('play', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('pause', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('stop', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('seekbackward', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('seekforward', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('seekto', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('previoustrack', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('nexttrack', function() { /* Code excerpted. */ });
//   navigator.mediaSession.setActionHandler('skipad', function() { /* Code excerpted. */ });
// }else{
//   alert(2)
// }



    },
    pauseStream: (state) => {
      state.isPlaying = false
    },

    // Play and Pause
    onPlay: (state, payload) => {
      state.isPlaying = true
    },
    onPause: (state) => {
      state.isPlaying = false
    },

    // Handle Loading Status
    onLoadStart: (state) => {
      state.isLoading = true
    },
    onLoadSuccess: (state, payload) => {
      
      state.isLoading = false
      
      if(payload){
        state.duration = payload.duration
      }

      state.error = null

    },
    onLoadFailure: (state, payload) => {
      state.error = payload
    },

    // File Playback Position
    setPosition: (state, payload) => {
      if(state.sources.broadcast){
        state.position = Math.floor(payload.currentTime)
      }
    },
    onSeek: (state, payload) => {
      state.seek = payload
      X(payload)
      state.isPlaying = true
    },
    onSlidingStart: (state) => {
      state.isPlaying = false
    },

    onPlaybackComplete: (state) => {
      state.isPlaying = false
    },

    clearError: (state) => {
      state.error = null
    },

  },

  actions: {

    play({commit}){

      if(!HowlerInstance || !HowlerInstance.play){
        X('noooooooooooo')
        return false
      }
      
      X('wooooooo')

      HowlerInstance.play()

      commit('onLoadStart')

    },

    pause({commit}){

      if(!HowlerInstance || !HowlerInstance.pause){
        return false
      }
      
      HowlerInstance.pause()

    },

    async playBroadcast({dispatch, commit, state}, broadcast){

      commit('activateBroadcast', broadcast)

      try{
        dispatch('loadActivatedSource')
      }catch(error){
        X(error)
      }

      X('wib wob')

      dispatch('play')

    },

    async playStream({dispatch, commit, state, rootState}, stream = null){

      X('woop')

      if(!stream){
        stream = rootState.creek.studio.streamPrimary
      }

      X(stream)

      commit('activateStream', stream)

      try{
        dispatch('loadActivatedSource')
      }catch(error){
        X(error)
      }

      X('wib wob')

      dispatch('play')

    },

    async loadActivatedSource({commit, state}){

      if(HowlerInstance){
        HowlerInstance.unload()
      }

      X('loading howler url:')
      X(state.URL)

      let sourceURL = state.URL

      if(state.hasStream){
        sourceURL = `${sourceURL}?no-cache=${Math.random()}`
        X('adding no-cache query string:')
        X(sourceURL)
      }


      Howler.autoUnlock = false;

      HowlerInstance = new Howl({
        src: [sourceURL],
        html5: true,
      })


      HowlerInstance.autoUnlock

      
      X(HowlerInstance)
      
      // Fires when the sound finishes playing.
      HowlerInstance.once('play', () => {
        commit('onPlay')
      })

      // Fires when the sound finishes playing.
      HowlerInstance.once('pause', () => {
        commit('onPause')
      })

      // Fires when the sound finishes playing.
      HowlerInstance.once('load', () => {

        X('Did commit() pass through? Let\'s see...')
        X(commit ? 'Yes! Here it is:' : 'No, it\'s not here:')
        X(commit)

        commit('onLoadSuccess')

      })

      HowlerInstance.on('loaderror', (error) => {
        commit('onLoadFailure', error)
      })

      HowlerInstance.on('end', () => {

        commit('onPlaybackComplete')

        /*
          
          TODO:

          Handle end of broadcast. 
          
          - Go to next broadcast? 
          - Display more broadcast suggestions? 
          - Display ending screen? 
            - YouTube Ending screen: Copy what YouTube does?

        */

      })

    },

    seek({commit}){

    }

  }


}
