
import CreekStudio from '../CreekStudio'

import GlobalPlayerModule from './GlobalPlayerModule'
import StudioModule from './StudioModule'


export default {

  namespaced: true,

  modules: {
    globalPlayer: GlobalPlayerModule,
    studio: StudioModule,
  },

  // state: {
  //   studioName: null
  // },

  // mutations: {
  //   setStudioName(state, studioName) {
  //     state.studioName = studioName
  //   }
  // }

}

