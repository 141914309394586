<template>
  <div id="app">

    <!-- <div class="nav-spacer"></div> -->

    <CreekWebAppHelper />

    <main>
      <router-view/>
    </main>

    <!--
    <CreekChatToolbar
      :isAlwaysOpen="true"
    /> 
    -->

    
    <div class="global-toolbar">
      <!-- <NavigationPanel /> -->
      <CreekBroadcastToolbar
        :includeShowSummary="false"
      />
      <NavigationPanel />
    </div>

    <CreekBroadcastToolbarSpacer/>
    
    <!-- TODO: <teleport> broadcast-toolbar from CreekBroadcastToolbar.vue to the <body> tag. -->

    <!-- TODO: Make sure spacer height is always toolbar height. -->
    <!-- TODO: Make sure spacer height is always toolbar height. -->


  </div>
</template>

<style lang="sass">

@import "./sass/basic-1.sass"
@import "./sass/basic-light-1.sass"

// .creek-broadcast-toolbar
//   position: relative
//   width: auto

// .global-toolbar
//   position: fixed
//   bottom: 0
//   width: 100%

</style>

<script>

import NavigationPanel from './components/NavigationPanel.vue'

import CreekChatToolbar from '@creek-website-toolkit/components/toolbars/ChatToolbar.vue'
import CreekBroadcastToolbar from '@creek-website-toolkit/components/toolbars/BroadcastToolbar.vue'
import CreekBroadcastToolbarSpacer from '@creek-website-toolkit/components/toolbars/BroadcastToolbarSpacer.vue'
import CreekWebAppHelper from '@creek-website-toolkit/components/helpers/CreekWebAppHelper.vue'

export default {
  name: 'App',
  components: {
    NavigationPanel,
    CreekChatToolbar,
    CreekBroadcastToolbar,
    CreekBroadcastToolbarSpacer,
    CreekWebAppHelper,
  },
  methods: {
    getActiveClass(routeName){
      if(this.$route.name == routeName){
        return { 'is-active': true }
      }else{
        return null
      }
    }
  },
  created(){
    // alert(1)
  }
}

</script>
