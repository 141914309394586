<template>
  <div 
    class="creek-web-app-helper"
    v-if="isIos"
    :class="{ 'is-open': isInstructionsBoxVisible }" 
  >

    <button 
      v-if="!isInstructionsBoxVisible && shouldShowInstallButton"
      @click="toggleInstructionsBoxVisible"
      class="creek-web-app-helper-button"
    >
      Install App
    </button>

    <div 
      v-if="isInstructionsBoxVisible" 
      class="creek-instructions-box-container"
    >

      <div  
        class="creek-instructions-box creek-instructions-fb-and-nonsafari"
        v-if="isFacebookApp || !isSafariOnIos" 
      >
        <ol 
        >

          <li v-if="isFacebookApp">
              Click the <span>three-dots</span> menu and select Open in Browser.
          </li>

          <li v-if="!isFacebookApp && !isSafariOnIos">Open your <b>Safari</b> app and go to: <b v-text="domain"></b></li>

          <li v-if="!isFacebookApp && !isSafariOnIos">Press this <b>Install App</b> button again.</li>

        </ol>

        <button 
          @click="toggleInstructionsBoxVisible"
          class="creek-web-app-helper-done-button"
        >
          Okay, got it!
        </button>

      </div>

      <div 
        class="creek-instructions-box creek-instructions-safari" 
        v-if="!isFacebookApp && isSafariOnIos"
      >
        <div class="creek-safari-instructions-bubble">
          <ol>
            <li>Press the <img class="creek-instructions-icon" src="https://icon-library.com/images/share-icon-iphone/share-icon-iphone-20.jpg" /> button below.</li>
            <li>Scroll the menu down.</li>
            <li>Select <b>Add to Homescreen</b>.</li>
          </ol>
        </div>
        
        <button 
          @click="toggleInstructionsBoxVisible"
          class="creek-web-app-helper-done-button"
        >
          Okay, got it!
        </button>

      </div>

      <div 
        class="creek-safari-instructions-arrow-down"
        v-if="isSafariOnIos"
      >
        <img src="../../icons/arrow-down-hand-drawn.svg" />
      </div>

    </div>

  </div>
</template>

<script>

import CreekBroadcastPanel from '@creek-website-toolkit/components/panels/BroadcastPanel.vue'

export default {
  name: 'CreekWebAppHelper',
  props: {
    
  },
  data() {
    return {
      instructionsBoxVisibleState: false,
      // installButtonVisibleState: true,
    }
  },
  methods: {
    toggleInstructionsBoxVisible(){
      
      this.$store.commit('creek/studio/toggleWebAppInstructionsBox')

      document.body.scrollTop = document.documentElement.scrollTop = 0

    },
    // showInstallButtonIfNeeded(){

    //   const mqStandAlone = '(display-mode: standalone)'

    //   // Only show if it's not already open as a PWA.
    //   if(navigator.standalone || window.matchMedia(mqStandAlone).matches){
    //     this.shouldShowInstallButton = false
    //   }
    //   else {
    //     this.shouldShowInstallButton = true
    //   }

    // }
  },
  computed: {
    isInstructionsBoxVisible(){
      return this.$store.state.creek.studio.UI.isWebAppInstructionsBoxVisible
    },
    shouldShowInstallButton(){

      const isStandalone = navigator.standalone 
        || window.matchMedia('(display-mode: standalone)').matches
      const isBrowser = navigator.browser 
        || window.matchMedia('(display-mode: browser)').matches

      // Only show if it's not already open as a PWA.
      if(isStandalone || isBrowser){
        return false
      }
      else {
        return true
      }

    },
    isFacebookApp() {
      var ua = navigator.userAgent || navigator.vendor || window.opera;
      return (ua.indexOf("FBAN") > -1) || (ua.indexOf("FBAV") > -1);
    },
    isIos(){
      return /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream
    },
    isSafari(){
      return !!navigator.userAgent.match(/Version\/[\d\.]+.*Safari/)
    },
    isSafariOnIos(){
      return this.isSafari && this.isIos
    },
    domain(){ 
      return window.location.host
    }
  },
  created(){

    // this.showInstallButtonIfNeeded()

  }
}
</script>
