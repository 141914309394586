
/*
===========================================================
  
  Creek Studio: Lib

===========================================================
*/

/*
-----------------------------------------------------------
  Import
-----------------------------------------------------------
*/

// NPM
import axios from 'axios'
import lodash from 'lodash'

/*
-----------------------------------------------------------
  Request Handlers
-----------------------------------------------------------
*/

const handleError = (error) => {
  
  errorMessage = error.message || error
  X('Error: ' + errorMessage)

  // TODO: Log to Sentry.

}

const handleAxiosResponseError = (axiosError) => {
  
  // TODO: Log to Sentry.
  
  // console.log(axiosError)

  // Get error from data if available.
  if(_.get(axiosError, 'response.data.error')){
    console.log(axiosError.response.data.error)
    Creek.response.error = axiosError.response.data.error
  }

  if(_.get(axiosError, 'response.data.errors')){
    console.log('woop')
    console.log(axiosError.response.data.errors)
    Creek.response.errors = axiosError.response.data.errors
  }

  return null

}

const responseHandler = async (axiosRequest) => {
  
  let response = null

  try{
    response = await axiosRequest
  }catch(responseError){
    // console.log(responseError)
    // console.log(responseError.response)
    return handleAxiosResponseError(responseError)
  }

  // Handle 200 response with error in its JSON.
  if(response.data && response.data.error){
    handleError(response.data.error)
    return null
  }

  // Handle responses both with and without data property.
  if(response.data && response.data.data){
    return response.data.data
  }else if(response.data){
    return response.data
  }

}

/*
-----------------------------------------------------------
  Main Object
-----------------------------------------------------------
*/

let CreekStudio = {

  get: async (resourceName = null, resourceId = null) => {
    
    let domain = null

    // TODO: Don't pollute window.
    if(window.$Creek && window.$Creek.domain){
      domain = window.$Creek.domain
    }else{
      throw Error('Creek: No domain set.')
    }

    if(!resourceName){
      throw Error('Creek: No resourceName specified.')
    }

    let path = resourceName

    path = path.replace(/\./g, '/')

    if(resourceId){
      path += '/' + resourceId
    }
    
    let URLPrefix = `https://${domain}/`

    // Use API path as default baseURL.
    const axiosInstance = axios.create({
      baseURL: URLPrefix + 'api/',
      timeout: 10000,
    })

    const result = await responseHandler(
      axiosInstance.get(path)
    )

    return result

  },

}

/*
-----------------------------------------------------------
  Export
-----------------------------------------------------------
*/

export default CreekStudio
