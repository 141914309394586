
<template>

<div 
  class="creek-chat-panel" 
>
  
  <div class="creek-chat-messages-container">
    <ul class="creek-chat-messages">
      <li v-for="message in messages">
        <span 
          v-if="message.type=='message'" 
          class="creek-chat-message-username" 
          v-text="message.username"
        ></span>
        <span 
          :class="'creek-chat-message-type-' + message.type" 
          v-text="message.content"
        ></span>
      </li>
    </ul>
    <ul class="creek-chat-messages-typing">
      <li v-for="typing in usersTyping">
        <span v-text="typing.username"></span> is typing.
      </li>
    </ul>
  </div>

  <div class="creek-chat-username-container" v-if="user.anonymous">
    <h3>Enter your name to start chat.</h3>
    <input 
      class="creek-chat-username-input" 
      @keyup.enter="editUsername" 
      type="text" 
      maxlength="14" 
      placeholder="Type your name..." 
      v-model="usernameInput"
    />
    <button
      @click="editUsername()"
      class="creek-chat-button-wide"
    >Start Chat</button>
  </div>

  <div class="creek-chat-input-container">
    <input 
      class="creek-chat-message-input" 
      v-model="messageInput" 
      @keyup.enter="messageSend" 
      placeholder="Type here..."
    />
  </div>

  <div 
    class="creek-chat-users"
    :class="{ 'creek-chat-users-open': isUsersOpen }"
  >
    <button 
      class="creek-chat-users-button"

      @click="toggleUsersOpen"
    >
      <span v-text="userCount"></span>
      <span v-text="userCount == 1 ? ' person here.' : ' people here.'"></span>
      <span class="creek-chat-users-button-expand">
        <b v-if="!isUsersOpen">more</b> 
        <b v-if="isUsersOpen">less</b> 
        <span v-if="!isUsersOpen">&darr;</span> 
        <span v-if="isUsersOpen">&uarr;</span> 
      </span>
    </button>
    <div class="creek-chat-users-inner" v-if="isUsersOpen">
      <h3>Name</h3>
      <div class="creek-chat-username">
        <input 
          class="creek-chat-username-input" 
          @keyup.enter="editUsername" 
          type="text" 
          maxlength="14" 
          placeholder="Enter your name to change it..." 
          v-model="usernameInput" 
        />
      </div>
      <br>
      <h3>People</h3>
      <ul class="creek-chat-users-list">
        <li v-for="user in users" v-text="user.username"></li>
      </ul>
    </div>
  </div>

</div>


</template>


<script>

const $ = require('jquery')

import { io } from "socket.io-client"

export default {

  props: ['stationId', 'socketAddress', 'debugEnabled'],

  data(){

    return {

      user: {
        
        username: "",

        //Users start out as anonymous
        anonymous: true

      },
      
      debugEnabled: false,

      socketAddress: null,

      chatSocket: {},

      messages: [],
      users: [],
      usersTyping: [],
      
      connected: false,

      messageInput: "",
      messageLastSent: '',

      usernameInput: "",

      isWindowOpen: false,
      isUsersOpen: false,

    }

  },

  methods: {

    debug(logOutput){
      if(this.debugEnabled){
        console.log(logOutput)
      }
    },

    editUsername(event) {

      X('editUsername')
      X(this.usernameInput)

      var newUsername = this.cleanInput(this.usernameInput)
      
      this.debug("Updating username.")

      this.user.username = newUsername

      // If the username is valid
      if (this.user.username) {

        this.debug("Done updating username.")

        this.user.username = newUsername

        //User is no longer anonymous
        this.user.anonymous = false
        
        $(".creek-chat-message-input").focus()

        // Tell the server your username
        this.chatSocket.emit('edit username', this.user.username)
      
        this.usernameInput = ''

      }

    },
    
    messageSend(){
      
      this.stopTyping()

      var message = this.messageInput

      // Prevent markup from being injected into the message
      message = this.cleanInput(message)

      this.debug("Attempting to send: " + message)

      // Only send if there is a non-empty message and a socket connection
      if (message && this.connected) {

        this.chatSocket.emit('send message', this.messageInput)

        this.debug("Message: " + this.user.username + ": " + this.messageInput)

        this.messageLastSent = this.messageInput + ""

        this.messageInput = ''

      }

    },
    
    updateMessages(messages){
      this.messages = messages
    },
    
    updateUsers(users){
      this.users = users
    },

    updateTyping(usersTyping){
      this.usersTyping = usersTyping
    },
    
    startTyping(){
      this.chatSocket.emit('start typing')
    },

    stopTyping(){
      this.chatSocket.emit('stop typing')
    },

    // Scrolls to the bottom of the chat window (used on new messages)
    scrollChatWindow() {

      $('.creek-chat-messages-container').each(function(){
        var scrollHeight = $(".creek-chat-messages").outerHeight()
        $(this).scrollTop(scrollHeight)
        $(this).scrollTop = scrollHeight
      })

    },

    // Scrolls to the bottom of the chat window (used on new messages)
    scrollChatWindowAnimate() {
      $('.creek-chat-messages-container').each(function(){
        var scrollHeight = $(".creek-chat-messages").outerHeight()
        $(this).animate({scrollTop: scrollHeight+"px"})
      })
    },

    // Prevents input from having injected markup
    cleanInput(input) {
      return $('<div/>').text(input).text()
    },

    logMessage(log){
      this.debug("Chat message: "+log)
      this.messages.push({
        type: "info",
        username: this.user.username,
        content: log
      })
    },

    toggleChatOpen(){
      this.isWindowOpen = !this.isWindowOpen
    },

    toggleUsersOpen(){
      this.isUsersOpen = !this.isUsersOpen
    },


    //------------------------------------------
    // Socket methods
    //------------------------------------------

    socket_connected() {
      X('connected to chat server')
      this.connected = true
      this.chatSocket.emit('set station', this.stationId)
      this.logMessage('Welcome.')
    },
 
    socket_disconnect() {
      this.connected = false
      this.logMessage('You have been disconnected.')
    },

    socket_reconnect() {
      if (this.user.username) {
        this.chatSocket.emit('add user', this.user.username)
      }
      this.logMessage('You have been reconnected.')
    },

    socket_reconnect_error() {
      this.logMessage('Attempt to reconnect has failed.')
    },

    // Update current username
    socket_updateUsername(username) {
      this.user.username = username
    },

    // Update the user list
    socket_updateUsers(users) {
      this.users = users
    },

    // Add a single new array element to messages
    socket_addMessage(data) {
      this.messages.push(data)
      setTimeout(() => {
        this.scrollChatWindowAnimate()
      }, 100)
      setTimeout(() => {
        this.scrollChatWindowAnimate()
      }, 200)
    },

    // Add a single new array element to messages
    socket_addInitialMessage(data) {

      this.messages.push(data)
      
      setTimeout(() => {
        this.scrollChatWindow()
      }, 100)
      
      setTimeout(() => {
        this.scrollChatWindow()
      }, 200)
    
    },

    // Update the chat body
    socket_updateMessages(data) {
      this.messages = data
      this.scrollChatWindow()
    },

    // Update the typing message
    socket_updateTyping(usersTyping) {
      this.usersTyping = usersTyping
      this.scrollChatWindow()
    },

  },
  computed: {

    studioDomain(){
      return this.$store.state.creek.studio.domain
    },

    // Gets the color of a username through a hash function
    usernameColor(){
      var usernameColors = [
        '#e21400', '#91580f', '#f8a700', '#f78b00',
        '#58dc00', '#287b00', '#a8f07a', '#4ae8c4',
        '#3b88eb', '#3824aa', '#a700ff', '#d300e7'
      ]
      // Compute hash code
      var hash = 7
      for (var i = 0; i < this.user.username.length; i++) {
         hash = this.user.username.charCodeAt(i) + (hash << 5) - hash
      }
      // Calculate color
      var index = Math.abs(hash % usernameColors.length)
      return usernameColors[index]
    },

    userCount(){
      return Object.keys(this.users).length
    },

    isTyping(){
      if(this.messageLastSent != this.messageInput){
        return true
      }else{
        return false
      }
    },

    chatSocketAddress(){
      if(this.socketAddress){
        return this.socketAddress
      }
      else{
        return `wss://${this.studioDomain}:3800`
        // return "http://192.168.86.250:3800"
      }
    },

    updated() {
      this.scrollChatWindow()
      this.debug("Window updated.")
      // var el = document.getElementById(chatWindow)
      // el.scrollTop = el.scrollHeight
    }

  },
  created(){

    this.scrollChatWindow()

    X('connecting to socket.io at: ' + this.chatSocketAddress)

    this.chatSocket = io(this.chatSocketAddress)
    // this.chatSocket = io("ws://192.168.86.250:3800")
    // this.chatSocket = io(this.chatSocketAddress)

    X(this.chatSocket)

    this.chatSocket.on("connected", () => {
      this.socket_connected()
      X('connected')
    })

    this.chatSocket.on("disconnect", () => {
      this.socket_disconnect()
      X('disconnect')
    })

    this.chatSocket.on("reconnect", () => {
      this.socket_reconnect()
      X('reconnect')
    })

    this.chatSocket.on("reconnect_error", () => {
      this.socket_reconnect_error()
      X('reconnect_error')
    })
    
    this.chatSocket.on("update username", (username) => {
      this.socket_updateUsername(username)
      X('update username')
    })

    this.chatSocket.on("update users", (users) => {
      this.socket_updateUsers(users)
      X('socket_updateUsers')
    })

    this.chatSocket.on("add message", (data) => {
      this.socket_addMessage(data)
      X('socket_addMessage')
    })

    this.chatSocket.on("add initial message", (data) => {
      this.socket_addInitialMessage(data)
      X('socket_addInitialMessage')
    })

    this.chatSocket.on("update messages", (data) => {
      this.socket_updateMessages(data)
      X('socket_updateMessages')
    })

    this.chatSocket.on("update typing", (usersTyping) => {
      this.socket_updateTyping(users)
      X('socket_updateTyping')
    })

  }
}

</script>
