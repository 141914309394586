<template>
  <div class="creek-broadcast-toolbar">

    <!-- Pass the props through to the BroadcastPanel. -->
    <CreekBroadcastPanel 
      :hasControls="hasControls"
      :includeShowSummary="includeShowSummary"
      :includeHosts="includeHosts"
      :includeTime="includeTime"
    />
    
  </div>
</template>

<script>

import CreekBroadcastPanel from '@creek-website-toolkit/components/panels/BroadcastPanel.vue'

export default {
  name: 'CreekBroadcastToolbar',
  props: {
    hasControls: {
      type: Boolean,
      default: true
    },
    includeShowSummary: {
      type: Boolean,
      default: true
    },
    includeHosts: {
      type: Boolean,
      default: true
    },
    includeTime: {
      type: Boolean,
      default: true
    },
  },
  components: {
    CreekBroadcastPanel
  },
  data(){
    return {

    }
  },
}
</script>
