<template>

    <!-- <audio controls>
     <source src="https://listen.atxsoulradio.com/stream" type="audio/mpeg"/>
    </audio> -->

  <button class="creek-player-control"
    @click="togglePlayback()" 
    :class="{ 
      'is-playing': isPlaying,
      'is-loading': isLoading,
      'is-broadcast': broadcast,
      'is-stream': stream,
    }"
    v-if="isPlayable"
  >
    <!-- asdasd -->

    <div class="creek-player-control-icon">
      <img 
        v-if="!isPlaying && !isLoading"
        src="../../icons/play.svg" 
      />
      <img 
        v-if="isPlaying && !isLoading" 
        src="../../icons/pause.svg" 
      />
      <img 
        v-if="!isPlaying && isLoading"
        src="../../icons/circle-notch.svg"
      />
    </div>
    

    <span 
      class="creek-player-control-text"
      v-text="buttonString"
    />
    
  </button>
  <div
    class="creek-player-control is-disabled"
    v-else-if="!onlyIfPlayable"
  >
    No Media
  </div>
</template>

<script>


let images = {}

for(let i in [
  "../../icons/play.svg",
  "../../icons/circle-notch.svg",
  "../../icons/pause.svg"
]){
  images[i] = new Image()
  images[i].src = i
}

export default {
  name: 'CreekPlayerControl',
  data(){
    return {}
  },
  computed: {
    globalPlayer() {
      return this.$store.state.creek.globalPlayer
    },
    globalPlayerStream() {
      return this.$store.state.creek.globalPlayer.sources.stream
    },
    globalPlayerBroadcast() {
      return this.$store.state.creek.globalPlayer.sources.broadcast
    },
    modelName(){
      if(this.broadcast){
        return 'Broadcast'
      }
      else if(this.stream){
        return 'Stream'
      }
    },
    modelData(){
      if(this.broadcast){
        return this.broadcast
      }
      else if(this.stream){
        return this.stream
      }
      return null
    },
    isSelected(){

      if(this.broadcast && this.globalPlayerBroadcast && this.broadcast == this.globalPlayerBroadcast.id){
        return true
      }
      if(this.stream && this.globalPlayerStream && this.stream.id == this.globalPlayerStream.id){
        return true
      }

      return false

    },
    isPlaying(){

      if(this.isSelected && this.globalPlayer.isPlaying){
        return true
      }

      return false

    },
    isLoading(){

      if(this.isSelected && this.globalPlayer.isLoading){
        return true
      }

      return false

    },

    isStream(){
      return this.stream && !this.broadcast ? true : false
    },

    isBroadcast(){
      return !this.stream && this.broadcast ? true : false
    },

    isAudio(){

      // TODO: Simplify these below after adding stream.type and broadcast.type to Studio.

      if(this.isStream){
        
        if(!this.stream.type){
          return true
        }

        // When Video is added:
        return this.stream.type == 'audio' ? true : false

        // ...but until then:
        // return true

      }else if(this.isBroadcast){
        return this.broadcast.audio ? true : false
      }

      return null

    },

    isVideo(){

      // TODO: Simplify these below after adding stream.type and broadcast.type to Studio.

      if(this.isStream){
        X('CHECKING STREAM VIDEO')
        return this.stream.type == 'video' ? true : false
      }else if(this.isBroadcast){
        return this.broadcast.video ? true : false
      }

      return null

    },

    isPlayable(){

      let val = false

      if(this.isBroadcast){
        X('playable: broadcast')
        val = (
                   _.get(this.broadcast, 'audio.url') 
                || _.get(this.broadcast, 'video.url') 
              )
              ? true : false
      }

      else if(this.isStream){
        X('playable: stream')
        X('wwoowewiaiohdoaiwdoiahwd')
        X(this.stream)
        val = _.get(this.stream, 'url') ? true : false
      }

      // X(`isPlayable:  ${val ? 'yes' : 'no'}`)

      return val

    },

    buttonString() {
      
      let string = ''

      if(this.isAudio){
        string = 'Listen'
        X('WOW LISTEN')
      }else if(this.isVideo){
        string = 'Watch'
        X('WOW WATCH')
      }else{
        string = 'Listen'
        X('WOW NOPE')
      }
      
      return string

    }
  },
  props: {
    broadcast: {
      type: Object,
      default: null,
    },
    stream: {
      type: Object,
      default: null,
    },
    onlyIfPlayable: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    togglePlayback(){
      if(this.isPlaying){
        this.$store.dispatch(
          'creek/globalPlayer/pause',
        )
      }else{
        this.$store.dispatch(
          'creek/globalPlayer/play' + this.modelName, this.modelData
        )
      }
    }
  }
}
</script>
