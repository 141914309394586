

<!-- This expands with the height of the BroadcastToolbar. -->

<template>
  <div 
    class="creek-broadcast-toolbar-spacer"
    :style="{ height: height + 'px' }"
  ></div>
</template>

<script>
  
export default {
  name: 'CreekBroadcastToolbarSpacer',
  computed: {
    height() {
      return 300
      // return this.$store.state.creek.studio.current
    },
  },
}
</script>
