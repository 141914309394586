
/*
-----------------------------------------------------------

  Navigation

  Uses Vue router if available. Falls back to window.location.

-----------------------------------------------------------
*/

const formatPath = (path) => {

  X(path)

  let pathFormatted = ''

  if(!path){
    pathFormatted = path
  }
  else if(path.indexOf('/') == 0){
    pathFormatted = path
  }
  else{
    pathFormatted = `/${path}`
  }

  X(pathFormatted)

  return pathFormatted

}

export default {

  to(path, router = null){

    const pathFormatted = formatPath(path)

    if(router){
      router.push(pathFormatted)
    }else{
      X('no router')
      window.location = pathFormatted
    }

  }

}
