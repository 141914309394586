
import CreekStudio from '../CreekStudio'


export default {

  namespaced: true,

  state () {
    return {

      domain: null,

      studio: null,

      current: {},

      streams: [],

      times: [],

      shows: [],

      broadcasts: [],
      
      show: null,

      broadcast: null,

      streamPrimary: null,

      UI: {
        isChatToolbarOpen: false,
        isVideoToolbarOpen: false,
        broadcastToolbarHeight: 0,
        isWebAppInstructionsBoxVisible: false,
      }

    }
  },

  mutations: {

    setDomain: (state, payload) => {
      state.domain = payload
    },

    // Set Main Objects
    setStudio: (state, payload) => {
      state.studio = payload
    },
    setCurrent: (state, payload) => {
      state.current = payload
    },
    setStreams: (state, payload) => {
      
      state.streams = payload

      X('streams:')
      X(payload)

      const streams = payload
      let streamPrimary = null

      // Use Stream where stream.purpose == 'main'
      for(let stream of streams){
        if(stream.purpose == 'main'){
          state.streamPrimary = stream
          X('primary stream: found main stream:')
          X(stream)
        }else{
          X('not main stream:')
          X(stream)
        }
      }

      // Default to the first one.
      if(!state.streamPrimary){
        X('primary stream: defaulting to [0]')
        state.streamPrimary = streams[0]
      }

    },
    setRecentBroadcasts: (state, payload) => {
      state.recentBroadcasts = payload
    },

    toggleChatToolbar: (state) => {
      state.UI.isChatToolbarOpen = !state.UI.isChatToolbarOpen
    },

    toggleVideoToolbar: (state) => {
      state.UI.isVideoToolbarOpen = !state.UI.isVideoToolbarOpen
    },

    toggleWebAppInstructionsBox: (state) => {
      state.UI.isWebAppInstructionsBoxVisible = !state.UI.isWebAppInstructionsBoxVisible
    },

  },

  actions: {

    async init({ rootState, state, commit, dispatch }, studioNameOrDomain){
      
      X(studioNameOrDomain)

      let domain = null

      if(studioNameOrDomain.includes('.')){
        domain = studioNameOrDomain
      }else{
        domain = studioNameOrDomain + '.studio.creek.org'
      }

      // TODO: Don't pollute window.
      if(!window.$Creek){
        window.$Creek = {}
      }

      // Pollute the window, so that we can access this from CreekStudio.js.
      window.$Creek.domain = domain

      commit('setDomain', domain)

      dispatch('getStudio')
      
      await dispatch('getStreams')

      commit('creek/globalPlayer/activateStream', state.streamPrimary, { root: true })

    },

    async getStudio({ state, commit }){
      commit('setStudio', await CreekStudio.get('studio'))
    },
    
    async getCurrent({ state, commit }){
      commit('setCurrent', await CreekStudio.get('current'))
    },

    async getStreams({ state, commit }){
      const streams = await CreekStudio.get('streams')
      commit('setStreams', streams)
    },

    async getTimes({ state, commit }){
      commit('setTimes', await CreekStudio.get('streams'))
    },

    async getRecentBroadcasts({ state, commit }){
      commit('setRecentBroadcasts', await CreekStudio.get('broadcasts/not-expired'))
    },

  }

}
